// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-js": () => import("./../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-podzespoly-i-urzadzenia-js": () => import("./../src/pages/podzespoly-i-urzadzenia.js" /* webpackChunkName: "component---src-pages-podzespoly-i-urzadzenia-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-regulamin-witryny-js": () => import("./../src/pages/regulamin-witryny.js" /* webpackChunkName: "component---src-pages-regulamin-witryny-js" */),
  "component---src-pages-serie-js": () => import("./../src/pages/serie.js" /* webpackChunkName: "component---src-pages-serie-js" */),
  "component---src-pages-skladanie-pc-js": () => import("./../src/pages/skladanie-pc.js" /* webpackChunkName: "component---src-pages-skladanie-pc-js" */),
  "component---src-pages-szukaj-js": () => import("./../src/pages/szukaj.js" /* webpackChunkName: "component---src-pages-szukaj-js" */),
  "component---src-pages-tag-js": () => import("./../src/pages/tag.js" /* webpackChunkName: "component---src-pages-tag-js" */),
  "component---src-templates-category-browser-js": () => import("./../src/templates/categoryBrowser.js" /* webpackChunkName: "component---src-templates-category-browser-js" */),
  "component---src-templates-post-browser-js": () => import("./../src/templates/postBrowser.js" /* webpackChunkName: "component---src-templates-post-browser-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tag-browser-js": () => import("./../src/templates/tagBrowser.js" /* webpackChunkName: "component---src-templates-tag-browser-js" */)
}

